import React from "react"
import { Link } from "gatsby"
import "../components/CSS/mentions.css"
import SEO from "../components/seo"


const MentionsLegalesPage = () => (
  <div id="app_mentions" class="container_mentions">
    <SEO title="Mentions Légales" />
    
    <h1>Mentions Légales:</h1>
    <div>Dénomination sociale : YDIL</div>
    <div>EURL au capital social de 1.000,00 euros</div>
    <div>Siret n° 841 186 968 000 19</div>
        <div class="mentions_block">
            <h2>1. Siège social :</h2>
            <div>33 avenue louis pasteur 92220 Bagneux</div>
            <div>contact@ydil.paris</div>
        </div>
        <div class="mentions_block">
            <h2>2. Hébergement web</h2>
            <div>IONOS by 1&1.</div>
        </div>
    
        <div class="mentions_block">
            <h2>3. Éditeur :</h2>
            <div>Ce site est la propriété de l'EURL YDIL.</div>
            <div>Responsable de la publication : Monsieur Flavien Montagné</div>
        </div>
        <div class="mentions_block">
            <h2>4. Création du site</h2>
            <div>YDIL</div>​
        </div>
    
        <Link className='retour' to="/">Retour</Link>
    
  </div>
)

export default MentionsLegalesPage
